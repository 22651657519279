.print-content.show-for-print {
	display: none;
}

@media print {
	
	// * {
	// 	background: transparent !important;
	// 	color: black !important;
	// 	box-shadow: none !important;
	// 	text-shadow: none !important;
	// }
	
	.briefcase-remove, .carousel-nav, .footer_first, .footer_second .container .row:first-child, .global-tools, .masthead, .nav-bar, .oe_menu, .rolepage #sidebar, .rolepage .sidebar, .subpage #sidebar, .subpage .sidebar, .topbar, #bc-wrapper, .main-content .widget, .control-group, #footer-row-1, .footer-row-2, .print-head, .print-head-main {
		display: none !important;
	}
		
	table {
		border-spacing: 0px;
	}

	td, th {
		padding: 0.3rem 0.625rem 0.625rem;
		text-align: left;
		border: 1px solid #999 !important;
		
	}

	.responsive-container {
		border: none !important;
	}

	.as-of-yield-left, .as-of-yield-right {
		text-align: center;
	}

	#pricesTable {
		margin-top: 1cm;
		border-left: 1px solid #999;
		border-right: 1px solid #999;
		border-top: 1px solid #999; 
	
		tr {
			border-bottom: 1px solid #999; 
		}
	}

	.print-header {
		position: running(headerIdentifier);
	}

	.print-content.show-for-print {
		display: block;
	}
		
	.show-for-print {
		display: block !important; 
	}
	
	.hide-for-print {
		display: none !important; 
	}

	.table  {
		.tophead {
			th {
				color: #ffffff !important;
				background-color: #ffffff !important;
				background-image: -moz-linear-gradient(top,#ffffff,#ffffff) !important;
				background-image: -webkit-gradient(linear,0 0,0 100%,from(#ffffff),to(#ffffff)) !important;
				background-image: -webkit-linear-gradient(top,#ffffff,#ffffff) !important;
				background-image: -o-linear-gradient(top,#ffffff,#ffffff) !important;
				background-image: linear-gradient(to bottom,#ffffff,#ffffff) !important;
				background-repeat: repeat-x
			}
		}

		.head {
			th {
				color: #ffffff !important;
				background-color: #ffffff !important;
				background-image: -moz-linear-gradient(top,#ffffff,#ffffff) !important;
				background-image: -webkit-gradient(linear,0 0,0 100%,from(#ffffff),to(#ffffff)) !important;
				background-image: -webkit-linear-gradient(top,#ffffff,#ffffff) !important;
				background-image: -o-linear-gradient(top,#ffffff,#ffffff) !important;
				background-image: linear-gradient(to bottom,#ffffff,#ffffff) !important;
				background-repeat: repeat-x
			}
		}
	}

	.tablesorter-header-inner {
		color: #ffffff !important;
	}
	
}

@media -ro-pdfreactor {
	@page {
		size: letter landscape;
		margin-top: 3.0cm;
		margin-right: 1.0cm;
		margin-bottom: 1.0cm;
		margin-left: 1.0cm;
		@top-left {
			content: element(headerIdentifier);
		}
	}

	@-ro-preferences {
		page-layout: 1 column;
		initial-zoom: fit-page; 
	}
	
	body {
		font-size: 8pt;
	}
}

